import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 핀다이렉트샵에 범용성 높은 ‘카카오 인증서’ 추가 도입",
  "| 2020년, 규제샌드박스 통해 비대면 간편인증 가입 시작한 스테이지파이브…”서비스 혁신 2막 열 것”",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/26/HySLxPVJ.jpg" style={{ maxWidth: "200px", margin: "auto" }} alt="Figure2" />
      </div>
      <div className="desc">
        △ 스테이지파이브(대표 서상원)가 14일 비대면 통신가입 본인인증 수단으로 ‘카카오 인증서’를 추가
        <br />
        했다. 사진은 스테이지파이브가 운영하는 브랜드 핀다이렉트샵 로고.
      </div>
    </div>
    <p>
      <br />
      <br />
      스테이지파이브(대표 서상원)가 비대면 통신가입 본인인증 수단으로 ‘카카오 인증서’를 추가해 알뜰폰가입의 편의성을 높였다.
      카카오 인증서 이용자는 현재 3,500만 명에 달한다.
      <br />
      <br />
      간편 인증 수단 추가 도입에 따라 스테이지파이브의 비대면 통신 가입 플랫폼 ‘핀다이렉트샵’ 이용자 는 온라인 통신 가입 시 지문,
      패턴, 비밀번호 등 간단한 방식으로 자신이 원하는 간편인증을 할 수 있게 됐다.
      <br />
      <br />
      모바일이나 PC를 통해 핀다이렉트샵(https://www.pindirectshop.com)에 접속한 후, 원하는 단말기나 요금제의 가입신청서를 작성할
      때 본인인증 수단으로 ‘카카오톡 인증’을 선택하면 된다.
      <br />
      <br />
      비대면 통신가입을 통해 고객 편의성 개선에 주력하고 있는 스테이지파이브는 이미 2020년 간편인증 도입을 골자로 한 ICT 규제
      샌드박스를 통과한 바 있다.
      <br />
      <br />
      기존에는 온라인 신규가입과 번호이동 시 본인 명의 신용카드를 통해 본인인증을 받아야 했다. 특히 대부분 온라인 가입 고객이
      이용하는 신용카드 인증 방식은 앱카드 미설치 시 카드번호를 직접 입력해야 하는 번거로움이 따랐다.
      <br />
      <br />
      <div className="descImage">
        <div className="img">
          <img src="/images/press/27/YxmbKcaw.png" style={{ maxWidth: "200px", margin: "auto" }} alt="Figure2" />
        </div>
        <div className="desc">
          △ 핀다이렉트샵의 비대면 통신 가입 절차 일부. 원하는 단말 및 통신상품을 선택한 뒤 위와 같
          <br />은 절차를 거치면 간편하게 통신 가입을 할 수 있다.
        </div>
      </div>
      <br />
      <br />
      스테이지파이브가 지난 2년간 ‘핀다이렉트샵’ 이용 고객의 간편인증 사용 추이를 살펴본 결과, 전체 가입자 중 약 70% 가 간편인증을
      활용한 것으로 나타났다.
      <br />
      <br />
      스테이지파이브는 앞으로도 고객 사용 패턴을 분석하여 이용자 친화적 UX를 선보일 예정이다. 스테이지파이브 관계자는 “국내
      유일하게 이동통신사(MNO, KT) 상품과 알뜰통신(MVNO)상품을 함께 판매하고 있는 플랫폼 핀다이렉트샵을 통해 비대면 통신가입
      서비스 혁신의 2막을 열 계획이다. 통신 가입을 원하는 이용자가 더욱 쉽고 간편하게, 그리고 안전하게 서비스를 이용할 수 있도록
      할 것”이라고 전했다.
      <br />
    </p>
  </div>
)

const press27 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴CR실"
      writer="커뮤니케이션팀 pr@stagefive.com "
      date="2022년 02월 17일 즉시 배포 가능"
      pageInfo="*총 2매 / 첨부사진 2매"
      title="스테이지파이브, 자사 핀다이렉트샵 내 카카오 인증서 도입<br/>3천만 넘는 이용자 확보한 카카오 인증서로 알뜰폰도 안전하고, 간편하게 가입하세요!"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press27
